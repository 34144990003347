<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Property valuation request</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Valuation request</span>
    </HeaderBasic>
    <div class="row">
      <div class="col-sm-12 col-md-8 col-md-offset-2">
        <PropertyValuation baseUrl="/Home/"></PropertyValuation>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic"),
    PropertyValuation: () => import("./Valuation")
  },
  data() {
    return {};
  },
  mounted() {
    this.$scrollTo(".custom-form");
  }
};
</script>
